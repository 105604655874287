import React, { Fragment } from "react";
import AppNavigations from "./AppNavigations";
import DashboardNavigations from "./DashboardNavigations";

const MainNavigation = () => {
  return (
    <Fragment>
      <DashboardNavigations />
      <AppNavigations />
    </Fragment>
  );
};

export default MainNavigation;
