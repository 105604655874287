import {
  CalendarMonth,
  ChevronRight,
  PersonOutline,
  FavoriteBorder,
  MessageOutlined,
  Share,
  ArrowForward,
  HorizontalRule,
} from "@mui/icons-material";
import React from "react";
import "../../styles/layoutSass/base.scss";
import "../../styles/layoutSass/blogs/base.scss";
import appColors from "../../utils/themes/colorThemes";

const BlogHome = () => {
  const categoriesList = [
    {
      catName: "Business",
      noOfPosts: 23,
    },
    {
      catName: "Search Optimization",
      noOfPosts: 8,
    },
    {
      catName: "Financial services",
      noOfPosts: 64,
    },
    {
      catName: "Tax returns",
      noOfPosts: 10,
    },
    {
      catName: "Digital marketing",
      noOfPosts: 42,
    },
    {
      catName: "Business",
      noOfPosts: 2,
    },
  ];
  const popularTags = [
    "Conference",
    "Business",
    "Event",
    "Web Development",
    "Speakers",
    "Technology",
    "Digital marketing",
  ];
  return (
    <div className="mainContentSection">
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Blogs"}</h1>
          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Blogs
          </p>
        </div>
      </div>
      <div className="centerGrid80" style={{ marginTop: "-25px" }}>
        <div className="blogsListMainCont">
          <div className="blogsListLHSCont">
            {[1, 2, 3, 4, 5, 6, 7].map((blog, i) => {
              return (
                <div className="singleBlogCont" key={i}>
                  <img
                    className="blogImg"
                    src={
                      "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                    }
                    alt="avancerBlogs"
                  />
                  <div className="blogsContentsCont">
                    <div className="blogActionsCont">
                      <div className="blogActionsContLHS">
                        <PersonOutline
                          fontSize="small"
                          className="blogactionIcons"
                        />
                        <p className="blogActionsTxt">Admin</p>
                        <CalendarMonth
                          fontSize="small"
                          className="blogactionIcons blogActionLeftPadding"
                        />
                        <p className="blogActionsTxt">March 20, 2023</p>
                        <MessageOutlined
                          fontSize="small"
                          className="blogactionIcons blogActionLeftPadding"
                        />
                        <p className="blogActionsTxt">97 comments</p>
                        <FavoriteBorder
                          fontSize="small"
                          className="blogactionIcons blogActionLeftPadding"
                        />
                        <p className="blogActionsTxt">1.5k likes</p>
                      </div>

                      <div className="blogActionsContRHS">
                        <Share
                          fontSize="small"
                          className="blogactionicons blogactionIconShare"
                        />
                        <p className="blogActionsTxt">Share This Post</p>
                      </div>
                    </div>
                    <p className="blogContHeadTxt">
                      Taking Actions for Benefits of Business
                    </p>
                    <p className="blogActionsTxt" style={{ marginTop: 10 }}>
                      No one rejects, dislikes, or avoids pleasure itself,
                      because it is pleasure, but because those who do not know
                      how to pursue pleasure rationally encounter consequences
                      that are extremely painful.
                    </p>
                    <div className="blogReadMoreCont">
                      <ArrowForward
                        fontSize="small"
                        className="blogactionicons blogactionIconShare"
                      />
                      <p className="blogReadMoreTxt">Read More</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="blogsListRHSCont">
            <div className="blogReadMoreCont">
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p className="blogReadMoreTxt blogsRHSHeads">Recent News</p>
            </div>
            {[1, 2, 3].map((recent, i) => {
              return (
                <div className="recentNewsCont" key={i}>
                  <img
                    className="recentNewsAvatar"
                    src={
                      "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                    }
                    alt="avancerBlogs"
                  />
                  <div className="recentNewsDetCont">
                    <p className="recentNewsHead">
                      Connecting people with Business
                    </p>
                    <div className="blogReadMoreCont">
                      <CalendarMonth
                        style={{ fontSize: 14 }}
                        className="blogactionicons blogactionIconShare"
                      />
                      <p className="recentNewsTime">March 15,2021</p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="blogReadMoreCont topMarginCommon">
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p className="blogReadMoreTxt blogsRHSHeads">Categories</p>
            </div>
            {categoriesList.map((cat, i) => {
              return (
                <div className="blogCatCont topMarginCommon" key={i}>
                  <p
                    className="categoriesTxt"
                    style={{ color: i === 3 ? appColors.red : appColors.grey }}
                  >
                    {cat.catName}
                  </p>
                  <p
                    className="categoriesTxt"
                    style={{ color: i === 3 ? appColors.red : appColors.grey }}
                  >
                    ({cat.noOfPosts})
                  </p>
                </div>
              );
            })}
            <div className="blogReadMoreCont topMarginCommon">
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p className="blogReadMoreTxt blogsRHSHeads">Popular Tags</p>
            </div>
            <div className="popularTagsCont topMarginCommon">
              {popularTags.map((tag, i) => {
                return (
                  <div className="singlePopularCont" key={i}>
                    <p className="categoriesTxt" style={{ fontSize: 12 }}>
                      {tag}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="officialAdsCont">
              <div className="adCurvTop"></div>

              <div className="advContactCont">
                <p className="advContactTxt">Contact for</p>
                <p className="advContactTxt">Advertisement</p>
                <p className="advContactTxt">270 x 310</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHome;
