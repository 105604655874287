import React, { Fragment } from "react";
import SiteNav from "./appnavigations/SiteNav";

const AppNavigations = () => {
  return (
    <Fragment>
      <SiteNav />
    </Fragment>
  );
};

export default AppNavigations;
