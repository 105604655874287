import {
  CalendarMonth,
  ChevronRight,
  PersonOutline,
  FavoriteBorder,
  MessageOutlined,
  Share,
  HorizontalRule,
  Reply,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@mui/icons-material";
import React, { useState } from "react";
import "../../styles/layoutSass/base.scss";
import "../../styles/layoutSass/blogs/base.scss";
import appColors from "../../utils/themes/colorThemes";

const BlogDetails = () => {
  const categoriesList = [
    {
      catName: "Business",
      noOfPosts: 23,
    },
    {
      catName: "Search Optimization",
      noOfPosts: 8,
    },
    {
      catName: "Financial services",
      noOfPosts: 64,
    },
    {
      catName: "Tax returns",
      noOfPosts: 10,
    },
    {
      catName: "Digital marketing",
      noOfPosts: 42,
    },
    {
      catName: "Business",
      noOfPosts: 2,
    },
  ];
  const popularTags = [
    "Conference",
    "Business",
    "Event",
    "Web Development",
    "Speakers",
    "Technology",
    "Digital marketing",
  ];

  const tagChimps = ["Finance", "Business", "Digital Marketing"];
  const [checked, setChecked] = useState(false);

  return (
    <div className="mainContentSection">
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Blogs"}</h1>
          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Blogs
          </p>
        </div>
      </div>
      <div className="centerGrid80" style={{ marginTop: "-25px" }}>
        <div className="blogsListMainCont">
          <div className="blogsListLHSCont">
            <div className="singleBlogCont singleBlogDetCont">
              <div className="blogImgDetCont">
                <img
                  className="blogDetImg"
                  src={
                    "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                  }
                  alt="avancerBlogs"
                />
                <div className="categNameBann">
                  <p className="postBtnTxt" style={{ fontSize: 12 }}>
                    BUSINESS
                  </p>
                </div>
              </div>

              <div className="blogsContentsCont">
                <div className="blogActionsCont">
                  <div className="blogActionsContLHS">
                    <PersonOutline
                      fontSize="small"
                      className="blogactionIcons"
                    />
                    <p className="blogActionsTxt">Admin</p>
                    <CalendarMonth
                      fontSize="small"
                      className="blogactionIcons blogActionLeftPadding"
                    />
                    <p className="blogActionsTxt">March 20, 2023</p>
                    <MessageOutlined
                      fontSize="small"
                      className="blogactionIcons blogActionLeftPadding"
                    />
                    <p className="blogActionsTxt">97 comments</p>
                    <FavoriteBorder
                      fontSize="small"
                      className="blogactionIcons blogActionLeftPadding"
                    />
                    <p className="blogActionsTxt">1.5k likes</p>
                  </div>
                </div>
                <p className="blogContHeadTxt">
                  Taking Actions for Benefits of Business
                </p>
                <p className="blogActionsTxt" style={{ marginTop: 10 }}>
                  No one rejects, dislikes, or avoids pleasure itself, because
                  it is pleasure, but because those who do not know how to
                  pursue pleasure rationally encounter consequences that are
                  extremely painful.
                </p>

                <p
                  className="blogActionsTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.blackColor,
                    fontWeight: "800",
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <p className="blogActionsTxt" style={{ marginTop: 10 }}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
                <p
                  className="blogContHeadTxt"
                  style={{ fontWeight: "500", color: appColors.blackColor }}
                >
                  How to become a top conference speaker
                </p>
                <p className="blogActionsTxt" style={{ marginTop: 10 }}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
                <div className="blogDetImgsCont">
                  <img
                    className="blogDetSingleImg"
                    src={
                      "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                    }
                    alt="avancerBlogs"
                  />
                  <img
                    className="blogDetSingleImg"
                    src={
                      "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                    }
                    alt="avancerBlogs"
                  />
                </div>
              </div>
            </div>
            <div className="blogActionsCont" style={{ marginTop: "25px" }}>
              <div className="blogActionsContLHS">
                {tagChimps.map((tagChimp, i) => {
                  return (
                    <div className="tagChimpsBlog" key={i}>
                      <p className="categoriesTxt">{tagChimp}</p>
                    </div>
                  );
                })}
              </div>
              <div className="blogActionsContRHS">
                <Share
                  fontSize="small"
                  className="blogactionicons blogactionIconShare"
                />
                <p className="blogActionsTxt">Share This Post</p>
              </div>
            </div>
            <div className="blogReadMoreCont" style={{ marginTop: 25 }}>
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p
                className="blogReadMoreTxt blogsRHSHeads"
                style={{ fontSize: 16 }}
              >
                Comments
              </p>
            </div>
            <div className="blogCommentsOutCont">
              <div className="blogDetCommentImgCont">
                <img
                  src={
                    "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                  }
                  alt="avancerBlogs"
                  className="blogDetCommentImg"
                />
              </div>
              <div className="commentsRHSCont">
                <div className="blogCommentsCont">
                  <p className="commentNameTxt">Robert Gates</p>
                  <div className="blogCommentTimeReply">
                    <p className="recentNewsTime" style={{ marginRight: 20 }}>
                      March 15,2021
                    </p>
                    <Reply
                      style={{ fontSize: 14 }}
                      className="blogactionicons blogactionIconShare"
                    />
                    <p className="recentNewsTime">Reply</p>
                  </div>
                </div>
                <p
                  className="blogActionsTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.grey,
                    fontWeight: "600",
                    marginLeft: 10,
                    lineHeight: 1.5,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>

            <div className="blogCommentsOutCont">
              <div className="blogDetCommentImgCont">
                <img
                  src={
                    "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                  }
                  alt="avancerBlogs"
                  className="blogDetCommentImg"
                />
              </div>
              <div className="commentsRHSCont">
                <div className="blogCommentsCont">
                  <p className="commentNameTxt">Amanda Tim</p>
                  <div className="blogCommentTimeReply">
                    <p className="recentNewsTime" style={{ marginRight: 20 }}>
                      March 15,2021
                    </p>
                    <Reply
                      style={{ fontSize: 14 }}
                      className="blogactionicons blogactionIconShare"
                    />
                    <p className="recentNewsTime">Reply</p>
                  </div>
                </div>
                <p
                  className="blogActionsTxt"
                  style={{
                    marginTop: 10,
                    color: appColors.grey,
                    fontWeight: "600",
                    marginLeft: 10,
                    lineHeight: 1.5,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>

            <div className="blogReadMoreCont" style={{ marginTop: 25 }}>
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p
                className="blogReadMoreTxt blogsRHSHeads"
                style={{ fontSize: 16 }}
              >
                Post a reply
              </p>
            </div>
            <textarea
              className="blogInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Comment *"
              // value={quoteDetails.message}
              onChange={(e) => {
                //   setDetails(e.target.value, "message");
              }}
            />
            <div className="commentsInputCont">
              <input
                className="blogInput blogInputSplit"
                placeholder="Name *"
                //   value={quoteDetails.name}
                onChange={(e) => {
                  // setDetails(e.target.value, "name");
                }}
              />
              <input
                className="blogInput blogInputSplit"
                placeholder="Email *"
                //   value={quoteDetails.name}
                onChange={(e) => {
                  // setDetails(e.target.value, "name");
                }}
              />
              <input
                className="blogInput blogInputSplit"
                placeholder="Website"
                //   value={quoteDetails.name}
                onChange={(e) => {
                  // setDetails(e.target.value, "name");
                }}
              />
            </div>
            <div className="postingActionsCont">
              <div className="blogsPostCommentBtn">
                <p className="postBtnTxt">POST BUTTON</p>
              </div>
              {checked ? (
                <CheckBox
                  fontSize="small"
                  onClick={() => setChecked(!checked)}
                  className="checkBoxBlogPost"
                />
              ) : (
                <CheckBoxOutlineBlank
                  fontSize="small"
                  className="checkBoxBlogPost"
                  onClick={() => setChecked(!checked)}
                />
              )}
              <p className="saveMyInfoTxt">Save my information for later use</p>
            </div>
          </div>
          <div className="blogsListRHSCont">
            <div className="blogReadMoreCont">
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p className="blogReadMoreTxt blogsRHSHeads">Recent News</p>
            </div>
            {[1, 2, 3].map((recent, i) => {
              return (
                <div className="recentNewsCont" key={i}>
                  <img
                    className="recentNewsAvatar"
                    src={
                      "https://assets.openstax.org/oscms-prodcms/media/original_images/AdobeStock_345576897.jpeg"
                    }
                    alt="avancerBlogs"
                  />
                  <div className="recentNewsDetCont">
                    <p className="recentNewsHead">
                      Connecting people with Business
                    </p>
                    <div className="blogReadMoreCont">
                      <CalendarMonth
                        style={{ fontSize: 14 }}
                        className="blogactionicons blogactionIconShare"
                      />
                      <p className="recentNewsTime">March 15,2021</p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="blogReadMoreCont topMarginCommon">
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p className="blogReadMoreTxt blogsRHSHeads">Categories</p>
            </div>
            {categoriesList.map((cat, i) => {
              return (
                <div className="blogCatCont topMarginCommon" key={i}>
                  <p
                    className="categoriesTxt"
                    style={{ color: i === 3 ? appColors.red : appColors.grey }}
                  >
                    {cat.catName}
                  </p>
                  <p
                    className="categoriesTxt"
                    style={{ color: i === 3 ? appColors.red : appColors.grey }}
                  >
                    ({cat.noOfPosts})
                  </p>
                </div>
              );
            })}
            <div className="blogReadMoreCont topMarginCommon">
              <HorizontalRule
                fontSize="small"
                className="blogactionicons blogactionIconShare"
              />
              <p className="blogReadMoreTxt blogsRHSHeads">Popular Tags</p>
            </div>
            <div className="popularTagsCont topMarginCommon">
              {popularTags.map((tag, i) => {
                return (
                  <div className="singlePopularCont" key={i}>
                    <p className="categoriesTxt" style={{ fontSize: 12 }}>
                      {tag}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="officialAdsCont">
              <div className="adCurvTop"></div>

              <div className="advContactCont">
                <p className="advContactTxt">Contact for</p>
                <p className="advContactTxt">Advertisement</p>
                <p className="advContactTxt">270 x 310</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
