import appArrowLogo from "./appImages/appArrowLogo.png";
import appArrowLogoBlack from "./appImages/appArrowLogoBlack.png";

import logo from "./appImages/logoBlack.png";
import noPage from "./appImages/404.png";
import navMainBanner from "./appImages/navMainBanner.png";

const appImages = {
  appImages: {
    appArrowLogo,
    appArrowLogoBlack,
    logo,
    noPage,
    navMainBanner,
  },
};

export default appImages;
