import React from "react";
import { Route, Routes } from "react-router";
import SiteLayout from "../../layouts/SiteLayout";
import BlogDetails from "../../screens/mainScreens/BlogDetails";
import BlogHome from "../../screens/mainScreens/BlogHome";
import NoScreen from "../../screens/mainScreens/NoScreen";
import AppRoute from "../AppRoute";

const SiteNav = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<AppRoute component={BlogHome} layout={SiteLayout} />}
      ></Route>
      <Route
        exact
        path="/home"
        element={<AppRoute component={BlogHome} layout={SiteLayout} />}
      ></Route>
      <Route
        exact
        path="/blogDet"
        element={<AppRoute component={BlogDetails} layout={SiteLayout} />}
      ></Route>
      <Route
        exact
        path="*"
        element={<AppRoute component={NoScreen} layout={SiteLayout} />}
      ></Route>
    </Routes>
  );
};

export default SiteNav;
