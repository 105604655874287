import React, { useLayoutEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const SiteLayout = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);

  React.useEffect(() => {
    let isActive = true;
    isActive && handleSize(window.innerWidth, window.innerHeight);
    isActive && window.addEventListener("resize", getCurrentScreenSize);
    return () => {
      isActive = false;
    };
  }, []);

  const getCurrentScreenSize = (e) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    handleSize(windowWidth, windowHeight);
  };
  const handleSize = (currentWidth, currentHeight) => {
    setCurrentWidth(currentWidth);

    if (currentWidth >= 700) {
      setIsMobileDevice(false);
    } else if (currentWidth < 700) {
      setIsMobileDevice(true);
    }
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([1920, 1080]);

        // setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  function ShowWindowDimensions(props) {
    const [width, height] = useWindowSize();
    console.log(width, height, "width, heightwidth, heightwidth, height");
    return (
      <span>
        Window size: {width} x {height}
      </span>
    );
  }
  {
    console.log(ShowWindowDimensions());
  }
  return (
    <div>
      <Header isMobileDevice={isMobileDevice} currentWidth={currentWidth} />
      <main style={{ marginBottom: "50px" }}>{children}</main>
      <Footer />
    </div>
  );
};

export default SiteLayout;
