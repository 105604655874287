import React, { useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import appImages from "../../assets";
import { Link, useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { LinkedIn, LocationOnSharp } from "@mui/icons-material";

const HeaderMobile = (props) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const currentRoute = location.pathname;
  const mobileMenubar = window.matchMedia("(min-width: 900px)").matches
    ? "35px"
    : window.matchMedia("(min-width: 800px)").matches
    ? "35px"
    : window.matchMedia("(min-width: 600px)").matches
    ? "35px"
    : window.matchMedia("(min-width: 500px)").matches
    ? "35px"
    : window.matchMedia("(min-width: 400px)").matches
    ? "30px"
    : window.matchMedia("(min-width: 200px)").matches
    ? "30px"
    : "30px";

  React.useEffect(() => {
    let isActive = true;
    return () => {
      let isActive = false;
    };
  }, [mobileMenubar]);

  return (
    <div className="mobileHeaderContainer" id="mobileHeader">
      <div className="applogoMobile">
        <img
          src={appImages.appImages.appArrowLogo}
          className="applogoWindowImage"
          alt="avancerpiAppLogo"
        />
        <span className="windowLogoTextWrapper">
          <p className="logoAvancerText">avancer</p>
          <p className="logoPiText">PI</p>
        </span>
      </div>
      <button
        className="menuBarBtn"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        {!menuOpen ? (
          <MenuOpenIcon
            className="headerMenuIcon"
            style={{ fontSize: mobileMenubar }}
          />
        ) : (
          <CancelIcon
            className="headerMenuIconBlue"
            style={{ fontSize: mobileMenubar }}
          />
        )}
      </button>
      {menuOpen && (
        <div className="menuBarMobile">
          <ul className="menuBarList">
            <li>
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                to={"/"}
                className={`menuBarItem  ${
                  currentRoute === "/" ? "activeMenuColor" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                to={"/About"}
                className={`menuBarItem  ${
                  currentRoute === "/About" ? "activeMenuColor" : ""
                }`}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                to={"/Services"}
                className={`menuBarItem  ${
                  currentRoute === "/DataCenter" ? "activeMenuColor" : ""
                }`}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                to={"/Career"}
                className={`menuBarItem  ${
                  currentRoute === "/Career" ? "activeMenuColor" : ""
                }`}
              >
                Career
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                to={"/ContactUs"}
                className={`menuBarItem  ${
                  currentRoute === "/ContactUs" ? "activeMenuColor" : ""
                }`}
              >
                Contact
              </Link>
            </li>
          </ul>
          <ul className="socialIconsMenuBtn">
            <li>
              <FacebookIcon
                className="socialIconsMenuBar"
                style={{ fontSize: `calc(${mobileMenubar} - 5px )` }}
              />
            </li>
            <li>
              <TwitterIcon
                className="socialIconsMenuBar"
                style={{ fontSize: `calc(${mobileMenubar} - 5px )` }}
              />
            </li>
            <li>
              <InstagramIcon
                className="socialIconsMenuBar"
                style={{ fontSize: `calc(${mobileMenubar} - 5px )` }}
              />
            </li>
            <li>
              <LinkedIn
                className="socialIconsMenuBar"
                style={{ fontSize: `calc(${mobileMenubar} - 5px )` }}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
