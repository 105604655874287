import * as Types from "../root.types";

const initialState = {
  selectedLanguage: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SELECTED_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };

    default:
      return state;
  }
};

export default authReducer;
