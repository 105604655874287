import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
// import MainDashboard from "../screens/dashboardScreens/MainDashboard";

import AppRoute from "./AppRoute";

const DashboardNavigations = () => {
  return (
    <Routes>
      {/* <Route
        exact
        path="/dashboard"
        element={
          <AppRoute component={MainDashboard} layout={DashboardLayout} />
        }
      ></Route> */}
    </Routes>
  );
};

export default DashboardNavigations;
