import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import MainNavigation from "./navigations/MainNavigation";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <MainNavigation />
      </Router>
    </Provider>
  );
}

export default App;
