import React from "react";

const AppRouteComp = ({ component: Component, layout: Layout, ...rest }) => {
  Layout = Layout === undefined ? (props) => <>{props.children}</> : Layout;
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default AppRouteComp;
