import React from "react";
// import { connect } from 'react-redux'
import appImages from "../../assets";
import "../../styles/componentSass/footer/_footer.scss";

//icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import appColors from "../../utils/themes/colorThemes";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
export const Footer = (props) => {
  const socialIconSize = window.matchMedia("(min-width: 900px)").matches
    ? "45px"
    : window.matchMedia("(min-width: 800px)").matches
    ? "40px"
    : window.matchMedia("(min-width: 600px)").matches
    ? "25px"
    : window.matchMedia("(min-width: 500px)").matches
    ? "35px"
    : window.matchMedia("(min-width: 400px)").matches
    ? "30px"
    : window.matchMedia("(min-width: 200px)").matches
    ? "35px"
    : "35px";
  const onIconsHover = (classId) => {
    // document.getElementsByClassName('socialIconsFooter')[classId].style.fontSize = `calc(${footerIcons} + 10px)`;
    // document.getElementsByClassName('socialIconsFooter')[classId].style.color = appColors.whiteColor;
  };
  const onIconsOut = (classId) => {
    // document.getElementsByClassName('socialIconsFooter')[classId].style.fontSize = footerIcons;
    // document.getElementsByClassName('socialIconsFooter')[classId].style.color = appColors.primaryColor;
  };
  return (
    <div className="footerContainer">
      <div className="footerInnerContainer centerGrid80">
        <div className="footerContentsWrapper">
          <div className="footersectionSeperation">
            <div className="footerSection1">
              <ul className="footerUl">
                <li>
                  <h1>About</h1>
                </li>
                <p className="aboutFooterContent">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <span className="mailAndCotactInfo">
                  <ContactPhoneIcon />
                  <p> &nbsp;&nbsp; +91 9902693693</p>
                </span>
                <span className="mailAndCotactInfo">
                  <EmailIcon />
                  <p> &nbsp;&nbsp;contactus@avancerpi.com</p>
                </span>
                <span className="mailAndCotactInfo">
                  <LocationOnIcon />
                  <p> &nbsp;&nbsp;Benson Town, Bangalore</p>
                </span>
              </ul>
            </div>
            <div className="footerSection2">
              <ul className="footerUl">
                <li>
                  <h1>Links</h1>
                </li>
                <li className="listWithLogoStyle" style={{ marginTop: 15 }}>
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Home</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">About us</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Services</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Terms of service</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName"> Privacy policy</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="segmentation" />
          <div className="footersectionSeperation">
            <div className="footerSection3">
              <ul className="footerUl">
                <li>
                  <h1>Explore</h1>
                </li>
                <li className="listWithLogoStyle" style={{ marginTop: 15 }}>
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Web Development</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Web Management</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Product Management</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Marketing</p>
                </li>
                <li className="listWithLogoStyle">
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Graphic Design</p>
                </li>
              </ul>
            </div>
            <div className="footerSection4">
              <ul className="footerUl">
                <li>
                  <h1>Social connections</h1>
                </li>
                <li className="listWithLogoStyle">
                  <Facebook fontSize="medium" className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    Facebook
                  </a>
                </li>
                <li className="listWithLogoStyle">
                  <Instagram fontSize="medium" className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    Instagram
                  </a>
                </li>
                <li className="listWithLogoStyle">
                  <Twitter fontSize="medium" className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    Twitter{" "}
                  </a>
                </li>
                <li className="listWithLogoStyle">
                  <LinkedIn fontSize="medium" className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(Footer)

export default Footer;
